import React from "react";
import FacebookIcon from "./facebook";
import InstagramIcon from "./instagram";
import WhatsAppIcon from "./whatsapp";

export default function IconsGroup() {
  return (
    <div className="d-flex">
      <div className="me-4">
        <FacebookIcon />
      </div>
      <div className="me-4">
        <InstagramIcon />
      </div>
      <div className="me-4">
        <WhatsAppIcon />
      </div>
    </div>
  );
}
