import React, { useState, useEffect } from "react";
import classesService from "../services/classes.service";
import { track } from "@amplitude/analytics-browser";
import CustomSection from "../components/section/Section";

import styles from "./NewHome.module.css";
import SectionCards from "../components/cards/SectionCards";
import Button from "../components/button/Button";

import bottom_yellow from "./assets/assets.new-home/bottom-yellow-2.png";
import top_yellow from "./assets/assets.new-home/top-yellow-2.png";
import top_white_desktop from "./assets/assets.new-home/new-top-purple.png";
import top_white_mobile from "./assets/assets.new-home/new-top-purple-mobile-2.png";
import RequestNewClass from "../components/requestNewClass/RequestNewClass";
import NoWorshop from "../components/error/NoWorshop";

function NewHome() {
  track("visita");
  const [available, setAvailable] = useState(undefined);
  const [unavailable, setUnavailable] = useState(undefined);

  function fetchData() {
    classesService
      .getAll("available")
      .then((resp) => {
        setAvailable(resp.data.data);

        if (resp.data.data === null) {
          setAvailable([]); //no hay talleres
        }
      })
      .catch((e) => {
        setAvailable([]); //no hay talleres
      });

    // fetching unavailable
    classesService
      .getAll("unavailable")
      .then((resp) => {
        setUnavailable(resp.data.data);
        if (resp.data.data === null) {
          setUnavailable([]); //no hay talleres
        }
      })
      .catch((e) => {
        setUnavailable([]); //no hay talleres
      });
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <div className={`col ${styles.first_section}`}>
        <picture>
          <source
            className={styles.top_white}
            media="(min-width: 780px)"
            srcset={top_white_desktop}
          />

          <img
            className={styles.top_white}
            src={top_white_mobile}
            alt="Descripción de la imagen"
          />
        </picture>

        <div className={`${styles.text_header}`}>
          <section class={``}>
            <div class="">
              <div class="">
                <h1 class={`fw-bold  ${styles.text_apprende}`}>Apprende</h1>
                <p class={`${styles.text_lead}`}>
                  La plataforma para compartir tus conocimientos y aprender de
                  los demás
                </p>
              </div>
            </div>
          </section>
        </div>
      </div>
      <div className={styles.disponibles}>
        <div className={`m-0  ${styles.white_section}`}>
          <CustomSection title={"Talleres recién aterrizados"} />
          <SectionCards n={8} typeclass={"available"} data={available}>
            <NoWorshop category={true} />
          </SectionCards>
          <div className="d-flex justify-content-center mt-5 pb-4">
            <Button
              type="link"
              color="yellow"
              url="/catalogo"
              state={{ filter: "available", data: available }}
            >
              Ver todos los talleres
            </Button>
          </div>
        </div>
      </div>
      <div className={styles.nodisponibles}>
        <img
          className={styles.top_yellow}
          src={top_yellow}
          alt="adorno top yellow"
        />
        <div className={`m-0 ${styles.yellow_section}`}>
          <CustomSection title={"Talleres Finalizados"} />
          <SectionCards n={8} typeclass={"unavailable"} data={unavailable}>
            <NoWorshop category={false} />
          </SectionCards>
          <div className="d-flex justify-content-center mt-5 pb-4">
            <Button
              type="link"
              url="/catalogo"
              state={{ filter: "unavailable", data: unavailable }}
            >
              Ver todos los talleres
            </Button>
          </div>
        </div>

        <img
          className={styles.bottom_yellow}
          src={bottom_yellow}
          alt="adorno bottom yellow"
        />
      </div>

      <RequestNewClass
        title={"Solicitar Taller"}
        classService={classesService}
      />
    </>
  );
}

export default NewHome;
