import React from "react";
import { Link } from "react-router-dom";
import styles from "./Button.module.css";

export default function Button({
  type,
  children,
  onClick,
  url = "/",
  color,
  state,
  preventScrollReset = false,
}) {
  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };

  if (type === "link") {
    if (state) {
      return (
        <Link
          className={`${styles.button} ${
            color === "yellow" ? styles.yellow : styles.purple
          }`}
          to={url}
          state={state}
          preventScrollReset={preventScrollReset}
        >
          {children}
        </Link>
      );
    } else {
      return (
        <Link to={url} preventScrollReset={preventScrollReset}>
          <div
            className={`${styles.button} ${
              color === "yellow" ? styles.yellow : styles.purple
            }`}
          >
            {children}
          </div>
        </Link>
      );
    }
  }

  return (
    <button
      onClick={handleClick}
      className={`${styles.button} ${
        color === "yellow" ? styles.yellow : styles.purple
      }`}
    >
      {children}
    </button>
  );
}
