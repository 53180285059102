import React from "react";
import styles from "./TextSectionDivider.module.css";

export default function TextSectionDivider({ text }) {
  return (
    <div className={`${styles.container}`}>
      <h3 className={`${styles.title}`}>{text}</h3>
      <div className={`${styles.line_container}`}>
        <div className={`${styles.line_head}`}></div>
        <div className={`${styles.line}`}></div>
        <div className={`${styles.line_head}`}></div>
      </div>
    </div>
  );
}
