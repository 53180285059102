import React from "react";
import CardCustom from "../../components/cards/Card";
import SkeletonCard from "./SkeletonCard";
import styles from "./Cards.module.css";

const SectionCards = ({ data, n, typeclass, children }) => {
  return (
    <>
      <div className="">
        {data === undefined || data === null ? (
          <div className="row row-cols-xl-4  row-cols-md-2 row-cols-1 g-4">
            {Array.from(Array(!n ? 20 : n).keys()).map((clase, index) => {
              return <SkeletonCard key={index} />;
            })}
          </div>
        ) : data.length === 0 ? (
          <div className="row d-flex justify-content-center pb-2 fw-light">
            <div className="m-4 mx-auto text-center" style={{ color: "black" }}>
              {children}
            </div>
          </div>
        ) : (
          <div className={`${styles.classes_grid}`}>
            {data.slice(0, !n ? 20 : n).map((clase, index) => {
              return (
                <CardCustom
                  isNew={typeclass === "available"}
                  clase={clase}
                  key={index}
                />
              );
            })}
          </div>
        )}
      </div>
    </>
  );
};

export default SectionCards;
