import React from "react";
import "./NavBarSimple.css";
import { Link, useLocation } from "react-router-dom";

const routes_nav = [
  { title: "Inicio", route: "/" },
  { title: "Nosotros", route: "/about" },
  // { title: 'Seguir mi clase', route: '/' },
  // { title: 'Para empresas', route: '/business' },
  // { title: 'Contacto', route: '/contact' },
];

function NavBarSimple() {
  let location = useLocation();
  let pathname = location.pathname;

  return (
    <>
      <nav
        class="navbar navbar-expand-md navbar-custom mb-auto" //mb-auto
        aria-label="Fourth navbar example"
      >
        <div class="container-fluid">
          <Link
            style={{
              color: "rgba(1, 1, 1, 0)",
              padding: "0",
              margin: "0",
            }}
            to="/"
          >
            <a href="/" className="navbar-brand logo">
              <img
                id="main-logo"
                src={require("../../images/logo_apprende.png")}
                alt="apprende_logo"
              />
              Apprende
            </a>
          </Link>
          <button
            class="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarsExample04"
            aria-controls="navbarsExample04"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <svg
              width="18"
              height="14"
              viewBox="0 0 18 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M0.75 13.25C0.75 13.0511 0.829018 12.8603 0.96967 12.7197C1.11032 12.579 1.30109 12.5 1.5 12.5H16.5C16.6989 12.5 16.8897 12.579 17.0303 12.7197C17.171 12.8603 17.25 13.0511 17.25 13.25C17.25 13.4489 17.171 13.6397 17.0303 13.7803C16.8897 13.921 16.6989 14 16.5 14H1.5C1.30109 14 1.11032 13.921 0.96967 13.7803C0.829018 13.6397 0.75 13.4489 0.75 13.25ZM0.75 7.25C0.75 7.05109 0.829018 6.86032 0.96967 6.71967C1.11032 6.57902 1.30109 6.5 1.5 6.5H16.5C16.6989 6.5 16.8897 6.57902 17.0303 6.71967C17.171 6.86032 17.25 7.05109 17.25 7.25C17.25 7.44891 17.171 7.63968 17.0303 7.78033C16.8897 7.92098 16.6989 8 16.5 8H1.5C1.30109 8 1.11032 7.92098 0.96967 7.78033C0.829018 7.63968 0.75 7.44891 0.75 7.25ZM0.75 1.25C0.75 1.05109 0.829018 0.860322 0.96967 0.71967C1.11032 0.579018 1.30109 0.5 1.5 0.5H16.5C16.6989 0.5 16.8897 0.579018 17.0303 0.71967C17.171 0.860322 17.25 1.05109 17.25 1.25C17.25 1.44891 17.171 1.63968 17.0303 1.78033C16.8897 1.92098 16.6989 2 16.5 2H1.5C1.30109 2 1.11032 1.92098 0.96967 1.78033C0.829018 1.63968 0.75 1.44891 0.75 1.25Z"
                fill="white"
              />
            </svg>
          </button>
          <div
            class="collapse navbar-collapse justify-content-end"
            id="navbarsExample04"
          >
            <ul class="navbar-nav ml-auto mb-2 mb-md-0 ">
              {routes_nav.map((item, index) => (
                <>
                  <li class="nav-item ">
                    <Link
                      style={{
                        textDecoration: "none",
                      }}
                      to={item.route}
                    >
                      <a
                        className={`nav-link ${
                          pathname === item.route ? "active" : ""
                        }`}
                        href={item.route}
                      >
                        {item.title}
                      </a>
                    </Link>
                  </li>
                </>
              ))}
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}

export default NavBarSimple;
