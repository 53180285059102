import React from "react";
import styles from "./AboutTutor.module.css";

function ProfessionalSolcialMedia({ data }) {
  return (
    <>
      {data.instagram && (
        <a target="_blank" rel="noopener noreferrer" href={data.instagram}>
          <svg
            width="30"
            height="30"
            viewBox="0 0 30 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15 30C23.2843 30 30 23.2843 30 15C30 6.71573 23.2843 0 15 0C6.71573 0 0 6.71573 0 15C0 23.2843 6.71573 30 15 30Z"
              fill="#3C0754"
            />
            <path
              d="M19.6571 9.3252C19.0873 9.3252 18.625 9.78749 18.625 10.3573C18.625 10.9271 19.0873 11.3894 19.6571 11.3894C20.2269 11.3894 20.6892 10.9271 20.6892 10.3573C20.6892 9.78749 20.2269 9.3252 19.6571 9.3252Z"
              fill="white"
            />
            <path
              d="M15.0728 10.6459C12.6839 10.6459 10.7422 12.5897 10.7422 14.9764C10.7422 17.3632 12.686 19.307 15.0728 19.307C17.4595 19.307 19.4033 17.3632 19.4033 14.9764C19.4033 12.5897 17.4595 10.6459 15.0728 10.6459ZM15.0728 17.7524C13.5418 17.7524 12.299 16.5074 12.299 14.9786C12.299 13.4498 13.544 12.2048 15.0728 12.2048C16.6016 12.2048 17.8466 13.4498 17.8466 14.9786C17.8466 16.5074 16.6016 17.7524 15.0728 17.7524Z"
              fill="white"
            />
            <path
              d="M18.5128 23.7708H11.4901C8.57655 23.7708 6.20703 21.4013 6.20703 18.4877V11.4651C6.20703 8.5515 8.57655 6.18195 11.4901 6.18195H18.5128C21.4263 6.18195 23.7959 8.5515 23.7959 11.4651V18.4877C23.7959 21.4013 21.4263 23.7708 18.5128 23.7708ZM11.4901 7.83762C9.49039 7.83762 7.8627 9.46534 7.8627 11.4651V18.4877C7.8627 20.4874 9.49039 22.1151 11.4901 22.1151H18.5128C20.5125 22.1151 22.1402 20.4874 22.1402 18.4877V11.4651C22.1402 9.46319 20.5125 7.83762 18.5128 7.83762H11.4901Z"
              fill="white"
            />
          </svg>
        </a>
      )}
      {data.tikTok && (
        <a
          className={data.instagram ? "ms-4" : ""}
          target="_blank"
          rel="noopener noreferrer"
          href={data.tikTok}
        >
          <svg
            width="30"
            height="30"
            viewBox="0 0 30 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15 30C23.2843 30 30 23.2843 30 15C30 6.71573 23.2843 0 15 0C6.71573 0 0 6.71573 0 15C0 23.2843 6.71573 30 15 30Z"
              fill="#3C0754"
            />
            <path
              d="M22.7892 13.2743C22.6365 13.2893 22.4817 13.2979 22.3269 13.2979C20.6368 13.2979 19.0585 12.4464 18.1318 11.0316V18.7509C18.1318 21.901 15.5773 24.4555 12.4272 24.4555C9.27713 24.4555 6.72266 21.901 6.72266 18.7509C6.72266 15.6008 9.27713 13.0464 12.4272 13.0464C12.5455 13.0464 12.6637 13.0571 12.7798 13.0636V15.8739C12.6637 15.8589 12.5476 15.8395 12.4272 15.8395C10.8188 15.8395 9.5158 17.1426 9.5158 18.7509C9.5158 20.3593 10.8188 21.6623 12.4272 21.6623C14.0356 21.6623 15.4569 20.3958 15.4569 18.7875L15.4848 5.67969H18.1748C18.4285 8.09224 20.3723 9.97584 22.7913 10.1522V13.2764"
              fill="white"
            />
          </svg>
        </a>
      )}
    </>
  );
}

export default function AboutTutor({ detail }) {
  return (
    <div className={styles.detail_profesioal_container}>
      <div className={styles.detail_profesioal_picture_container}>
        {detail?.profesorData.professorPicture === "" ? (
          <div
            className={`${styles.professor_without_pic} mx-auto mb-3 mt-3`}
          ></div>
        ) : (
          <div className={`${styles.detail_professorpicture_container}`}>
            <figure>
              <svg
                width="423"
                height="256"
                viewBox="0 0 423 256"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M421.236 165.08C432.136 84.1613 324.973 78.3789 270.029 85.6025C273.094 68.41 268.529 29.2659 225.747 10.2299C172.27 -13.5651 85.5163 10.3212 21.3194 61.1949C-42.8775 112.069 60.2699 217.387 116.413 200.772C172.556 184.157 187.931 170.67 255.121 223.772C322.31 276.874 407.611 266.228 421.236 165.08Z"
                  fill="#FAC300"
                  stroke="#FEEDB3"
                />
              </svg>
              <img
                className={`${styles.detail_professorpicture}`}
                src={detail?.profesorData.professorPicture}
                alt="First slide"
              />
            </figure>
          </div>
        )}
        <h2 className={styles.detail_profesioal_name}>
          {detail.profesorData.professorName}
        </h2>
        <h3 className={styles.detail_profesioal_occupation}>
          {detail.profesorData.professorOccupation}
        </h3>
        <div className={styles.detail_profesioal_picture_social_media}>
          <ProfessionalSolcialMedia data={detail.profesorData} />
        </div>
      </div>
      <div className={styles.detail_profesioal_experience}>
        <h3>Experiencia</h3>
        <p>{detail.profesorData.professorExperience}</p>
        <div className={styles.detail_profesioal_experience_social_media}>
          <ProfessionalSolcialMedia data={detail.profesorData} />
        </div>
      </div>
    </div>
  );
}
