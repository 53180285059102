import { React, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import classesService from "../services/classes.service";
import DetailClassOptional from "../components/detail-class/DetailClassOptional";
import { track } from "@amplitude/analytics-browser";

function Clase() {
  let { id } = useParams();
  const [detail, setDetail] = useState(null);
  let eventProperties = {
    claseId: id,
  };
  track("visita-clase", eventProperties);

  useEffect(() => {
    // fetch classes
    classesService.getDetail(id).then((resp) => {
      var _detail = resp.data.data;
      setDetail(_detail);
    });
  }, [id]);

  if (!detail) {
    return <div></div>;
  }

  return (
    <div>
      {/**      <Breadcrumb
        pages={[
          { name: 'Clases', path: '/', active: false },
          { name: 'Detalle', path: `/clase/${id}`, active: true },
        ]}
      />  */}

      <DetailClassOptional detail={detail} id={id} />
    </div>
  );
}

export default Clase;
