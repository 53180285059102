import { React, useState } from "react";
import "./Inscribirse.css";
import Swal from "sweetalert2";
import classesService from "../../services/classes.service";
import styles from "./CustomButton.module.css";
import "react-phone-number-input/style.css";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";

function Inscribirse(props) {
  const id = props.id;
  const [userName, setUserName] = useState();
  const [userAge, setUserAge] = useState();
  const [userUbication, setUbication] = useState();
  // const [userEmail, setUserEmail] = useState('');
  // const [userEmailRepeat, setUserEmailRepeat] = useState('');

  const [userEmail, setUserEmail] = useState({ value: "", valid: undefined });
  const [userEmailRepeat, setUserEmailRepeat] = useState({
    value: "",
    valid: undefined,
  });

  const [isValidEmailRepeat, setIsValidEmailRepeat] = useState(undefined);

  const [userRut, setUserRut] = useState("");
  const [userSansano, setUserSansano] = useState("");
  const [userCarrera, setUserCarrera] = useState("");
  const [phoneInput, setPhoneInput] = useState("");
  const [campusSansano, setCampusSansano] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    var payload = {
      classId: parseInt(id),
      userName: userName,
      age: parseInt(userAge),
      city: userUbication,
      email: userEmail.value,
      taller: props.className,
      dia: props.fecha,
      comuna: props.comuna,
      hora: props.hora,
      subject: "Confirmación de inscripción a taller interactivo USM",
      rut: userRut,
      sansano: userSansano,
    };
    classesService
      .postInscribirClase(payload)
      .then(function (res) {
        Swal.fire({
          confirmButtonColor: "#6F119B",
          text: "Solicitud de inscripción realizada correctamente",
          icon: "success",
          showConfirmButton: true,
          confirmButtonText: "Entendido",
        }).then(function (res) {
          Swal.close();
          document.getElementById("topCloseButtonModal").click();
        });
        return res;
      })
      .catch(function (res) {
        Swal.fire({
          showConfirmButton: true,
          confirmButtonColor: "#6F119B",
          title: "Error!",
          text: "Tuvimos un error con nuestros sistemas, puedes comunicarte a través de nuestras redes sociales para solicitar ayuda.",
          icon: "error",
          confirmButtonText: "Entendido",
        }).then(function (res) {
          Swal.close();
          document.getElementById("topCloseButtonModal").click();
        });
      });
  };

  const validEmailRegex = RegExp(
    /^((([^<>()[\].,;:\s@"]+(.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+.)+[^<>()[\].,;:\s@"]{2,})|)$/i
  );

  function onChangeEmail(e) {
    var _email = e.target.value;
    setUserEmail((prevState) => ({ ...prevState, value: _email }));

    if (validEmailRegex.test(_email)) {
      setUserEmail((prevState) => ({ ...prevState, valid: true }));
    } else {
      setUserEmail((prevState) => ({ ...prevState, valid: false }));
    }
    // check repeat
    if (_email === userEmailRepeat.value) {
      setIsValidEmailRepeat(true);
    } else {
      setIsValidEmailRepeat(false);
    }
    return true;
  }

  function onChangeEmailRepeat(e) {
    var _email = e.target.value;
    setUserEmailRepeat((prevState) => ({ ...prevState, value: _email }));

    if (validEmailRegex.test(_email)) {
      setUserEmailRepeat((prevState) => ({ ...prevState, valid: true }));
    } else {
      setUserEmailRepeat((prevState) => ({ ...prevState, valid: false }));
    }
    // check repeat
    if (userEmail.value === _email) {
      setIsValidEmailRepeat(true);
    } else {
      setIsValidEmailRepeat(false);
    }
    return true;
  }

  const [isValidRut, setIsValidRut] = useState(null);
  function validarRUT(rut) {
    // Eliminar puntos y guiones
    rut = rut.replace(/[.-]/g, "");

    // Dividir el RUT y el dígito verificador
    let cuerpo = rut.slice(0, -1);
    let dv = rut.slice(-1).toUpperCase();

    // Calcular el dígito verificador
    let suma = 0;
    let multiplo = 2;

    for (let i = cuerpo.length - 1; i >= 0; i--) {
      suma += multiplo * parseInt(cuerpo[i]);
      multiplo = multiplo < 7 ? multiplo + 1 : 2;
    }

    let verificador = 11 - (suma % 11);
    if (verificador === 10) verificador = "K";
    if (verificador === 11) verificador = "0";

    return dv === verificador.toString();
  }

  const handleRutChange = (e) => {
    setUserRut(e.target.value);
    setIsValidRut(validarRUT(e.target.value));
  };

  const handleUserSansanoChange = (e) => {
    const value = e.target.value === "true";
    setUserSansano(value);
  };

  const handleCampusChange = (e) => {
    const value = e.target.value;
    setCampusSansano(value);
  };

  const handleEventSubmit = async (e) => {
    e.preventDefault();
    var payload = {
      eventId: id,
      studentName: userName,
      email: userEmail.value,
      studentRut: userRut,
      career: userCarrera,
      campus: campusSansano,
      phoneNumber: phoneInput,
    };
    classesService
      .postEventInscription(payload)
      .then(function (res) {
        Swal.fire({
          confirmButtonColor: "#6F119B",
          text: "Inscripción realizada correctamente",
          icon: "success",
          showConfirmButton: true,
          confirmButtonText: "Entendido",
        }).then(function (res) {
          Swal.close();
          document.getElementById("topCloseButtonModal").click();
        });
        return res;
      })
      .catch(function (res) {
        Swal.fire({
          showConfirmButton: true,
          confirmButtonColor: "#6F119B",
          title: "Error!",
          text: "Tuvimos un error con nuestros sistemas, puedes comunicarte a través de nuestras redes sociales para solicitar ayuda.",
          icon: "error",
          confirmButtonText: "Entendido",
        }).then(function (res) {
          Swal.close();
          document.getElementById("topCloseButtonModal").click();
        });
      });
  };

  if (props.formType === "usm") {
    return (
      <div style={{ margin: 0, padding: 0 }}>
        <div className="col px-2 mx-auto ">
          <button
            type="button"
            data-bs-toggle="modal"
            data-bs-target="#staticBackdrop"
            className={`btn  btn-sm ${styles.new_button}`}
          >
            Inscribirme
          </button>
        </div>
        <div
          className="modal fade"
          id="staticBackdrop"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          // tabindex='-1'
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div
                className="modal-header"
                style={{ backgroundColor: "#FEC700" }}
              >
                <div className="modal-title" id="staticBackdropLabel">
                  <h2 className="form_header_title">
                    Formulario de inscripción
                  </h2>
                  <h4 className="pl-2 form_header_subtitle">
                    Clase de <b className="bold">{props.className}</b>
                    {/* Clase de <b style={{color: '#470467'}}>{props.className}</b> */}
                  </h4>
                </div>
                <button
                  id="topCloseButtonModal"
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <form
                  onSubmit={handleEventSubmit}
                  id="registerToClassForm"
                  className="needs-validation insc_modal_body"
                >
                  <div className="mb-3">
                    <label
                      htmlFor="inputUserName"
                      className="form-label insc_label_class"
                    >
                      Nombre
                    </label>
                    <input
                      type="text"
                      className="form-control insc_input"
                      id="inputUserName"
                      placeholder="Ej: Javier Montenegro"
                      onChange={(e) => setUserName(e.target.value)}
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <label
                      htmlFor="inputUserName"
                      className="form-label insc_label_class"
                    >
                      Rut
                    </label>
                    <input
                      type="text"
                      className="form-control insc_input"
                      id="inputUserRut"
                      placeholder="Ej: 19.346.797-0"
                      onChange={handleRutChange}
                      required
                    />
                    {isValidRut === true && (
                      <span style={{ color: "green", marginTop: "10px" }}>
                        RUT válido
                      </span>
                    )}
                    {isValidRut === false && (
                      <span style={{ color: "red", marginTop: "10px" }}>
                        RUT inválido
                      </span>
                    )}
                  </div>
                  <div className="mb-3">
                    <label
                      htmlFor="inputUserSansano"
                      className="form-label insc_label_class"
                    >
                      Campus
                    </label>
                    <select
                      id="inputUserSansano"
                      className="form-control"
                      value={campusSansano}
                      onChange={handleCampusChange}
                    >
                      <option value="" disabled>
                        Seleccione una opción
                      </option>
                      <option value="cc">Casa Central</option>
                      <option value="sj">San Joaquin</option>
                      <option value="csv">Vitacura</option>
                      <option value="vm">Viña del mar</option>
                      <option value="svm">Concepsión</option>
                    </select>
                    {campusSansano === "" && (
                      <span style={{ color: "red" }}>
                        Selecciona una opción para continuar
                      </span>
                    )}
                  </div>
                  {/* Ubicacion */}
                  <div className="mb-3">
                    <label
                      htmlFor="inputUserUbication"
                      className="form-label insc_label_class"
                    >
                      Carrera
                    </label>
                    <input
                      type="inputCity"
                      className="form-control insc_input"
                      id="inputUserUbication"
                      placeholder="Ej: Ingenieria Civil"
                      onChange={(e) => setUserCarrera(e.target.value)}
                      required
                    />
                  </div>
                  {/* Correo */}
                  <div className="mb-3">
                    <label
                      htmlFor="inputUserEmail"
                      className="form-label insc_label_class"
                    >
                      Correo electrónico
                    </label>
                    <input
                      value={userEmail.value}
                      type="email"
                      className={`form-control insc_input ${
                        userEmail.valid === true
                          ? "is-valid"
                          : userEmail.valid === false
                          ? "is-invalid"
                          : ""
                      }`}
                      id="inputUserEmail"
                      placeholder="nombre@ejemplo.com"
                      onChange={onChangeEmail}
                      required
                    />
                    <div className="invalid-feedback">Correo no válido.</div>
                  </div>
                  {/* Correo repetir */}
                  <div className="mb-5">
                    <label
                      htmlFor="inputUserEmailRepeat"
                      className="form-label insc_label_class"
                    >
                      Repetir correo electrónico
                    </label>
                    <input
                      value={userEmailRepeat.value}
                      type="email"
                      className={`form-control insc_input ${
                        userEmailRepeat.valid === true
                          ? "is-valid"
                          : userEmailRepeat.valid === false
                          ? "is-invalid"
                          : ""
                      }`}
                      id="inputUserEmailRepeat"
                      placeholder="nombre@ejemplo.com"
                      onChange={onChangeEmailRepeat}
                      required
                    />
                    <div className="invalid-feedback">Correo no válido.</div>
                  </div>
                  {/* ALERT */}
                  {isValidEmailRepeat === true ? (
                    <div className="alert alert-success" role="alert">
                      'Los correos coinciden!'
                    </div>
                  ) : isValidEmailRepeat === false ? (
                    <div className="alert alert-danger" role="alert">
                      'Los correos no coinciden!'
                    </div>
                  ) : (
                    <></>
                  )}

                  {/* Ubicacion */}
                  <div className="mb-3">
                    <label
                      htmlFor="inputUserPhone"
                      className="form-label insc_label_class"
                    >
                      Tu numero
                    </label>
                    <PhoneInput
                      international
                      id="inputUserPhone"
                      defaultCountry="CL"
                      value={phoneInput}
                      onChange={setPhoneInput}
                      error={
                        phoneInput
                          ? isValidPhoneNumber(phoneInput)
                            ? undefined
                            : "Invalid phone number"
                          : "Phone number required"
                      }
                    />
                  </div>

                  {/* SUBMIT */}
                  <div className="modal-footer">
                    <button
                      type="submit"
                      className="btn insc_send_btn"
                      disabled={
                        !isValidEmailRepeat ||
                        !userEmailRepeat.valid ||
                        !userEmail.valid ||
                        campusSansano === "" ||
                        !isValidPhoneNumber(phoneInput) ||
                        !isValidRut
                      }
                    >
                      Enviar
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div style={{ margin: 0, padding: 0 }}>
      <div className="col px-2 mx-auto ">
        <button
          type="button"
          data-bs-toggle="modal"
          data-bs-target="#staticBackdrop"
          className={`${styles.new_button}`}
        >
          Inscribirme
        </button>
      </div>
      {/* <button
        type='button'
        className='detail-btn-inscription-class'
        data-bs-toggle='modal'
        data-bs-target='#staticBackdrop'
      >
        Solicitar inscripción
      </button> */}
      <div
        className="modal fade"
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        // tabindex='-1'
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div
              className="modal-header"
              style={{ backgroundColor: "#FEC700" }}
            >
              <div className="modal-title" id="staticBackdropLabel">
                <h2 className="form_header_title">Formulario de inscripción</h2>
                <h4 className="pl-2 form_header_subtitle">
                  Clase de <b className="bold">{props.className}</b>
                  {/* Clase de <b style={{color: '#470467'}}>{props.className}</b> */}
                </h4>
              </div>
              <button
                id="topCloseButtonModal"
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form
                onSubmit={handleSubmit}
                id="registerToClassForm"
                className="needs-validation insc_modal_body"
              >
                <div className="mb-3">
                  <label
                    htmlFor="inputUserName"
                    className="form-label insc_label_class"
                  >
                    Nombre
                  </label>
                  <input
                    type="text"
                    className="form-control insc_input"
                    id="inputUserName"
                    placeholder="Ej: Javier Montenegro"
                    onChange={(e) => setUserName(e.target.value)}
                    required
                  />
                </div>
                <div className="mb-3">
                  <label
                    htmlFor="inputUserName"
                    className="form-label insc_label_class"
                  >
                    Rut
                  </label>
                  <input
                    type="text"
                    className="form-control insc_input"
                    id="inputUserRut"
                    placeholder="Ej: 19.346.797-0"
                    onChange={handleRutChange}
                    required
                  />
                  {isValidRut === true && (
                    <span style={{ color: "green", marginTop: "10px" }}>
                      RUT válido
                    </span>
                  )}
                  {isValidRut === false && (
                    <span style={{ color: "red", marginTop: "10px" }}>
                      RUT inválido
                    </span>
                  )}
                </div>
                <div className="mb-3">
                  <label
                    htmlFor="inputUserSansano"
                    className="form-label insc_label_class"
                  >
                    ¿Eres Alumno de la USM?
                  </label>
                  <select
                    id="inputUserSansano"
                    className="form-control"
                    value={userSansano}
                    onChange={handleUserSansanoChange}
                  >
                    <option value="" disabled>
                      Seleccione una opción
                    </option>
                    <option value="true">Soy sansano</option>
                    <option value="false">No soy sansano</option>
                  </select>
                  {userSansano === "" && (
                    <span style={{ color: "red" }}>
                      Selecciona una opción para continuar
                    </span>
                  )}
                </div>
                <div className="mb-3">
                  <label
                    htmlFor="inputUserAge"
                    className="form-label insc_label_class"
                  >
                    Edad
                  </label>
                  <input
                    type="number"
                    className="form-control insc_input"
                    id="inputUserAge"
                    placeholder="Ej: 20"
                    min={"1"}
                    max="99"
                    onChange={(e) => setUserAge(e.target.value)}
                    required
                  />
                </div>
                {/* Ubicacion */}
                <div className="mb-3">
                  <label
                    htmlFor="inputUserUbication"
                    className="form-label insc_label_class"
                  >
                    Ciudad
                  </label>
                  <input
                    type="inputCity"
                    className="form-control insc_input"
                    id="inputUserUbication"
                    placeholder="Ej: Santiago, Chile"
                    onChange={(e) => setUbication(e.target.value)}
                    required
                  />
                </div>
                {/* Correo */}
                <div className="mb-3">
                  <label
                    htmlFor="inputUserEmail"
                    className="form-label insc_label_class"
                  >
                    Correo electrónico Sansano
                  </label>
                  <input
                    value={userEmail.value}
                    type="email"
                    className={`form-control insc_input ${
                      userEmail.valid === true
                        ? "is-valid"
                        : userEmail.valid === false
                        ? "is-invalid"
                        : ""
                    }`}
                    id="inputUserEmail"
                    placeholder="nombre@ejemplo.com"
                    onChange={onChangeEmail}
                    // onChange={(e) => {
                    //   setUserEmail(e.target.value);
                    //   if (e.target.value === userEmailRepeat) {
                    //     setIsValidEmailRepeat(true);
                    //   } else {
                    //     // input is valid -- reset the error message
                    //     setIsValidEmailRepeat(false);
                    //   }
                    // }}
                    required
                  />
                  <div className="invalid-feedback">Correo no válido.</div>
                </div>
                {/* Correo repetir */}
                <div className="mb-5">
                  <label
                    htmlFor="inputUserEmailRepeat"
                    className="form-label insc_label_class"
                  >
                    Repetir correo electrónico
                  </label>
                  <input
                    value={userEmailRepeat.value}
                    type="email"
                    className={`form-control insc_input ${
                      userEmailRepeat.valid === true
                        ? "is-valid"
                        : userEmailRepeat.valid === false
                        ? "is-invalid"
                        : ""
                    }`}
                    id="inputUserEmailRepeat"
                    placeholder="nombre@ejemplo.com"
                    onChange={onChangeEmailRepeat}
                    // onChange={(e) => {
                    //   setUserEmailRepeat(e.target.value);
                    //   if (userEmail === e.target.value) {
                    //     setIsValidEmailRepeat(true);
                    //   } else {
                    //     // input is valid -- reset the error message
                    //     setIsValidEmailRepeat(false);
                    //   }
                    // }}
                    required
                  />
                  <div className="invalid-feedback">Correo no válido.</div>
                </div>
                {/* ALERT */}
                {isValidEmailRepeat === true ? (
                  <div className="alert alert-success" role="alert">
                    'Los correos coinciden!'
                  </div>
                ) : isValidEmailRepeat === false ? (
                  <div className="alert alert-danger" role="alert">
                    'Los correos no coinciden!'
                  </div>
                ) : (
                  <></>
                )}
                {/*                 
                <div
                  className={`alert ${
                    isValidEmailRepeat ? 'alert-success' : 'alert-danger'
                  }`}
                  role='alert'
                >
                  {isValidEmailRepeat
                    ? 'Los correos coinciden!'
                    : 'Los correos no coinciden'}
                </div> */}

                {/* SUBMIT */}
                <div className="modal-footer">
                  <button
                    type="submit"
                    className="btn insc_send_btn"
                    disabled={
                      !isValidEmailRepeat ||
                      !userEmailRepeat.valid ||
                      !userEmail.valid ||
                      userSansano === "" ||
                      !isValidRut
                    }
                  >
                    Enviar
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Inscribirse;
