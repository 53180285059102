import React from "react";

export default function WhatsAppIcon() {
  return (
    <a
      href="https://wa.me/56986045567"
      target="_blank"
      rel="noopener noreferrer"
    >
      <svg
        width="41"
        height="41"
        viewBox="0 0 41 41"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M20.875 40.7266C31.9207 40.7266 40.875 31.7723 40.875 20.7266C40.875 9.68087 31.9207 0.726562 20.875 0.726562C9.82931 0.726562 0.875 9.68087 0.875 20.7266C0.875 31.7723 9.82931 40.7266 20.875 40.7266Z"
          fill="#E6B325"
        />
        <path
          d="M10.0664 32.0449L11.735 25.8236C10.7918 24.1206 10.2929 22.2112 10.2929 20.2731C10.2929 13.94 15.4477 8.78516 21.7808 8.78516C28.114 8.78516 33.2688 13.94 33.2688 20.2731C33.2688 26.6063 28.114 31.7611 21.7808 31.7611C19.88 31.7611 18.0022 31.2823 16.3193 30.3677L10.0664 32.042V32.0449ZM16.6317 28.0369L17.0246 28.272C18.4695 29.132 20.1151 29.5879 21.7808 29.5879C26.9184 29.5879 31.0985 25.4079 31.0985 20.2703C31.0985 15.1326 26.9184 10.9526 21.7808 10.9526C16.6432 10.9526 12.4632 15.1326 12.4632 20.2703C12.4632 21.9675 12.9333 23.6361 13.825 25.1011L14.0658 25.4967L13.1341 28.9715L16.6289 28.034L16.6317 28.0369Z"
          fill="white"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M26.0587 21.8732C25.5857 21.5893 24.9693 21.274 24.4131 21.5033C23.9859 21.6782 23.7135 22.3462 23.4354 22.6874C23.2921 22.8623 23.123 22.8909 22.9051 22.8021C21.2996 22.1627 20.0725 21.0933 19.1866 19.6169C19.0375 19.3875 19.0633 19.2069 19.2439 18.9947C19.5106 18.6793 19.846 18.321 19.9205 17.8967C19.9922 17.4724 19.7944 16.9764 19.6195 16.5979C19.3959 16.1163 19.1436 15.4254 18.6619 15.153C18.2176 14.9007 17.6327 15.0412 17.2342 15.3651C16.5519 15.9213 16.2193 16.7929 16.2308 17.6587C16.2308 17.9053 16.2652 18.149 16.3225 18.3869C16.4601 18.9574 16.7239 19.4907 17.022 19.9953C17.2457 20.3766 17.4894 20.7464 17.7503 21.1048C18.6075 22.2717 19.6769 23.2837 20.9125 24.0406C21.5289 24.419 22.194 24.7516 22.885 24.9781C23.6562 25.2332 24.3443 25.4999 25.1814 25.3393C26.0559 25.173 26.916 24.6341 27.2629 23.7912C27.3661 23.5418 27.4177 23.2637 27.3604 22.9999C27.2428 22.4552 26.506 22.1312 26.0645 21.8703L26.0587 21.8732Z"
          fill="white"
        />
      </svg>
    </a>
  );
}
