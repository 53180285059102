import React from "react";

export default function InstagramIcon() {
  return (
    <a
      href="https://www.instagram.com/apprendechile/?hl=es"
      target="_blank"
      rel="noopener noreferrer"
    >
      <svg
        width="41"
        height="41"
        viewBox="0 0 41 41"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M20.9375 40.7266C31.9832 40.7266 40.9375 31.7723 40.9375 20.7266C40.9375 9.68087 31.9832 0.726562 20.9375 0.726562C9.89181 0.726562 0.9375 9.68087 0.9375 20.7266C0.9375 31.7723 9.89181 40.7266 20.9375 40.7266Z"
          fill="#E6B325"
        />
        <path
          d="M27.1456 13.1602C26.3859 13.1602 25.7695 13.7766 25.7695 14.5363C25.7695 15.296 26.3859 15.9125 27.1456 15.9125C27.9054 15.9125 28.5218 15.296 28.5218 14.5363C28.5218 13.7766 27.9054 13.1602 27.1456 13.1602Z"
          fill="white"
        />
        <path
          d="M21.0358 14.9219C17.8506 14.9219 15.2617 17.5136 15.2617 20.696C15.2617 23.8783 17.8535 26.47 21.0358 26.47C24.2182 26.47 26.8099 23.8783 26.8099 20.696C26.8099 17.5136 24.2182 14.9219 21.0358 14.9219ZM21.0358 24.3972C18.9946 24.3972 17.3374 22.7372 17.3374 20.6988C17.3374 18.6604 18.9974 17.0004 21.0358 17.0004C23.0743 17.0004 24.7342 18.6604 24.7342 20.6988C24.7342 22.7372 23.0743 24.3972 21.0358 24.3972Z"
          fill="white"
        />
        <path
          d="M25.6186 32.4245H16.255C12.3703 32.4245 9.21094 29.2651 9.21094 25.3803V16.0168C9.21094 12.1321 12.3703 8.97266 16.255 8.97266H25.6186C29.5034 8.97266 32.6628 12.1321 32.6628 16.0168V25.3803C32.6628 29.2651 29.5034 32.4245 25.6186 32.4245ZM16.255 11.1802C13.5888 11.1802 11.4185 13.3505 11.4185 16.0168V25.3803C11.4185 28.0466 13.5888 30.2169 16.255 30.2169H25.6186C28.2849 30.2169 30.4552 28.0466 30.4552 25.3803V16.0168C30.4552 13.3477 28.2849 11.1802 25.6186 11.1802H16.255Z"
          fill="white"
        />
      </svg>
    </a>
  );
}
