import React from "react";

export default function FacebookIcon() {
  return (
    <a
      href="https://www.facebook.com/apprende.cl"
      target="_blank"
      rel="noopener noreferrer"
    >
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M39.9983 19.9992C39.9983 8.95318 31.0451 0 19.9992 0C8.95318 0 0 8.95318 0 19.9992C0 29.9815 7.31337 38.2553 16.8743 39.7546V25.7787H11.7971V19.9963H16.8743V15.5899C16.8743 10.5787 19.8587 7.8093 24.4284 7.8093C26.6158 7.8093 28.9065 8.19919 28.9065 8.19919V13.1216H26.3836C23.8981 13.1216 23.124 14.6639 23.124 16.2436V19.9963H28.6714L27.7855 25.7787H23.1269V39.7546C32.6878 38.2553 40.0012 29.9815 40.0012 19.9992H39.9983Z"
          fill="#E6B325"
        />
        <path
          d="M27.7852 25.7819L28.6711 19.9995H23.1238V16.2468C23.1238 14.6643 23.8978 13.1248 26.3834 13.1248H28.9062V8.20239C28.9062 8.20239 26.6184 7.8125 24.4282 7.8125C19.8584 7.8125 16.8741 10.5819 16.8741 15.5931V19.9995H11.7969V25.7819H16.8741V39.7578C17.8918 39.9184 18.9353 40.0015 19.9989 40.0015C21.0625 40.0015 22.1061 39.9184 23.1238 39.7578V25.7819H27.7824H27.7852Z"
          fill="white"
        />
      </svg>
    </a>
  );
}
