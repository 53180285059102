import { React } from "react";
import Inscribirse from "./Inscribirse";
import NotEnableStudentInscriptions from "./NotEnableStudentInscriptions";
import styles from "./DetailClassOptional.module.css";
import InterestedCompanyInClass from "./InterestedCompanyInClass";
import AboutWorkshop from "./AboutWorkshop";
import AboutTutor from "./AboutTutor";
import { dateValue, hourValue } from "../../helpers";
import TextSectionDivider from "../text-section-divider/TextSectionDivider";

import { Link } from "react-router-dom";

function DetailClassOptional(props) {
  let today = new Date();

  const InscribirseButton = () => {
    let datesBoolean = props.detail?.dates?.map((content) => {
      return new Date(content.DateClass) > today;
    });
    let isEnable = false;
    for (var index in datesBoolean) {
      if (datesBoolean[index] === true) {
        isEnable = true;
        break;
      }
    }
    if (isEnable) {
      if (props.detail?.precio === "0") {
        return (
          <Inscribirse
            id={props.id}
            className={props.detail?.title}
            comuna={props.detail?.ubicacion}
            fecha={dateValue(props.detail?.dateAndHour)}
            hora={hourValue(props.detail?.dateAndHour)}
            formType={props.detail?.formType}
          />
        );
      } else {
        return (
          <Link
            style={{
              color: "white",
              padding: "0",
              margin: "0",
              textDecoration: "none",
            }}
            to={`/buy-class/${props.id}`}
            preventScrollReset={false}
          >
            <div className="col px-2 mx-auto ">
              <button
                type="button"
                className={`btn  btn-sm ${styles.button_yellow}`}
              >
                Comprar clase
              </button>
            </div>
          </Link>
        );
      }
    } else {
      return (
        <div style={{ display: "flex", gap: "1rem" }}>
          <NotEnableStudentInscriptions
            id={props.id}
            className={props.detail?.title}
          />
          <InterestedCompanyInClass
            id={props.id}
            className={props.detail?.title}
          />
        </div>
      );
    }
  };

  return (
    <div className={`${styles.container}`}>
      <section className={`${styles.container_details}`}>
        {/* <div className={styles.breadcrumb}>
          <a href="#">Inicio</a> <a href="#">Taller de guitarra</a>
        </div> */}

        <h1 className={styles.detail_title_class}>{props.detail?.title}</h1>

        <div className={styles.detail_content}>
          <div className={styles.detail_content_description_container}>
            <h2>Descripción</h2>
            <p className={styles.detail_title_description_class}>
              {props.detail?.description}
            </p>

            <InscribirseButton />
          </div>
          <div className={styles.container_details_image}>
            <img
              src={
                props.detail?.multimediaClass.length === 0
                  ? "https://test-apprende.s3.amazonaws.com/publicaci%C3%B3n+1.png"
                  : props.detail?.multimediaClass[0].url
              }
              alt={props.detail?.multimediaClass[0].description}
            />
            {/* <button class="btn btn-secondary">
              <i class="fa fa-share"></i> Compartir
            </button> */}
          </div>
        </div>
      </section>

      <section>
        <TextSectionDivider text="Contenidos" />
        <ul className={`${styles.detail_content_class} ${styles.ul_class}`}>
          {props.detail?.contenido?.map((content, index) => {
            return (
              <li key={index} className={`${styles.class_content}`}>
                {content}
              </li>
            );
          })}
        </ul>
      </section>

      <section>
        <TextSectionDivider text="Detalles del Taller" />
        <AboutWorkshop details={props.detail} />
      </section>
      <section>
        <TextSectionDivider text="Acerca del Tutor" />
        <AboutTutor detail={props.detail} />
      </section>
      {/*<section>// Actualizar esto a la brevedad
        <TextSectionDivider text="Sesiones anteriores de este evento" />
        <NewImageCarrusel images={props.detail?.multimediaClass} />
      </section>*/}
      {/* professor details */}
    </div>
  );
}

export default DetailClassOptional;
